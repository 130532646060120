import sha256 from "fast-sha256"
import { decodeUTF8 } from "tweetnacl-util"
import { Buffer } from "buffer"

/**
 * Hash some `content` to its SHA256 representation
 */
const hash = (content: string) => Buffer.from(sha256(decodeUTF8(content))).toString("hex")

export default hash
