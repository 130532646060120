import React from "react"
import { faFingerprint, faFileLines, faLock } from "@fortawesome/free-solid-svg-icons"
import { Handle, Position } from "reactflow"

import Box from "./content/Box"
import BlockData from "./BlockData"
import Table from "./content/Table/index"

import styles from "./Block.module.css"

export interface Props {
    index: number
    content: string
    prevHash: string
    ownHash: string
    setContent: (newContent: string) => void
}

function Block({ data }: { data: Props }) {
    return (
        <Box radius={15} shadow={4}>
            {data.prevHash !== "GENESIS BLOCK" ? <Handle type="target" position={Position.Left} style={{ top: "34%" }} /> : ""}
            <Handle type="source" position={Position.Right} style={{ top: "86%" }} />

            <h3 className={styles["block-heading"]}>Block #{data.index}</h3>

            <Table>
                <tbody>
                    <BlockData icon={faLock} heading="Basis:" text={data.prevHash} />
                    <BlockData icon={faFileLines} heading="Basis:">
                        <textarea
                            value={data.content}
                            onChange={(e) => data.setContent(e.target.value)}
                            rows={3}
                            className={styles["textarea-no-resize"]}
                        />
                    </BlockData>
                    <BlockData icon={faFingerprint} heading="Fingerabdruck:" text={data.ownHash} />
                </tbody>
            </Table>
        </Box>
    )
}

export default Block
