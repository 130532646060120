import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import ReactFlow, { Background, Node, applyNodeChanges, NodeChange, Edge, ReactFlowInstance } from "reactflow"
import "reactflow/dist/style.css"

import styles from "./App.module.css"
import Block, { Props as BlockProps } from "./components/Block"
import store from "./model/Chain"

const proOptions = { hideAttribution: true }

const nodeTypes = { block: Block }

export interface Props {
    nodes: Node<BlockProps>[]
    edges: Edge[]
}

const onFlowInit = (instance: ReactFlowInstance) => {
    instance.fitView({ duration: 1000 })
}

function App(props: Props) {
    const onNodesChange = useCallback(
        (changes: NodeChange[]) => store.applyNodeChanges((currentNodes: Node[]) => applyNodeChanges(changes, currentNodes)),
        []
    )

    return (
        <div className={styles["app"]}>
            <ReactFlow
                nodes={props.nodes}
                edges={props.edges}
                onNodesChange={onNodesChange}
                onInit={onFlowInit}
                nodeTypes={nodeTypes}
                nodesDraggable={false}
                nodesConnectable={false}
                proOptions={proOptions}
            >
                <Background />
            </ReactFlow>
        </div>
    )
}

export default observer(() => <App nodes={store.nodes} edges={store.edges} />)
