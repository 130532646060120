import { makeObservable, observable, computed, action } from "mobx"
import { Edge, MarkerType, Node } from "reactflow"

import { Props as BlockProps } from "../components/Block"
import hash from "../utils/hash"

export class Chain {
    /**
     * List of content of blocks. Every entry corresponds to a distinct block
     */
    contents: string[]

    /**
     * Raw nodes used to compute final nodes (these are changed by react-flow itself)
     */
    rawNodes: Node<any>[]

    constructor(contents: string[]) {
        makeObservable(this, {
            contents: observable,
            rawNodes: observable,
            editContent: action.bound,
            applyNodeChanges: action.bound,
            nodes: computed,
            edges: computed,
        })
        this.contents = contents

        // Compute initial nodes
        this.rawNodes = this.contents.map((_, i) => ({
            id: i.toString(),
            type: "block",
            position: {
                x: 350 * i,
                y: 100,
            },
            data: {},
        }))
    }

    /**
     * Edit the content of a block
     * @param index block content to edit
     * @param newContent new content to set
     */
    editContent(index: number, newContent: string) {
        this.contents = [...this.contents.slice(0, index), newContent, ...this.contents.slice(index + 1)]
    }

    /**
     * Update rawNodes
     * @param getNewNodes function that computes new nodes based on the current ones
     */
    applyNodeChanges(getNewNodes: (currentNodes: Node[]) => Node[]) {
        this.rawNodes = getNewNodes(this.rawNodes)
    }

    /**
     * List of precomputed nodes for react-flow
     */
    get nodes(): Node<BlockProps>[] {
        const chain = this.contents
        const nodes = this.rawNodes

        // Infuse rawNodes
        const infusedNodes: Node<BlockProps>[] = []
        for (let i = 0; i < chain.length; ++i) {
            let prevHash = (i !== 0 ? infusedNodes[i - 1].data.ownHash : "GENESIS BLOCK").slice(0, 16)
            let content = chain[i]
            let ownHash = hash(`${prevHash}|${content}`).slice(0, 16)

            infusedNodes.push({
                ...nodes[i],
                data: {
                    index: i,
                    prevHash,
                    content,
                    ownHash,
                    setContent: (newContent: string) => this.editContent(i, newContent),
                },
            })
        }

        return infusedNodes
    }

    /**
     * Edges that connect blocks (nodes)
     */
    get edges(): Edge[] {
        const nodes = this.rawNodes

        // Build edges between nodes
        const edges: Edge[] = []
        for (let i = 0; i < nodes.length - 1; ++i) {
            edges.push({
                id: `${i}-${i + 1}`,
                source: i.toString(),
                target: (i + 1).toString(),
                type: "step",
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                },
                style: {
                    strokeWidth: 3,
                    stroke: "#FF0072",
                },
            })
        }

        return edges
    }
}

//! Initial data
const store = new Chain([
    "The Times 03/Jan/2009 Chancellor on brink of second bailout for banks",
    "To the moon 🚀",
    "I'm in it for the technology!\n\n-97% Performance",
])

export default store
