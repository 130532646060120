import React, { useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import ChangeHighlight from "react-change-highlight"

import Text from "./content/Text"
import TableRow from "./content/Table/TableRow"
import TableCell from "./content/Table/TableCell"

export interface Props {
    icon?: IconProp
    heading: string
    text?: string
    children?: React.ReactNode
}

function BlockData(props: Props) {
    const ref = useMemo(() => React.createRef<HTMLSpanElement>(), [])

    return (
        <TableRow>
            <TableCell> {props.icon ? <FontAwesomeIcon icon={props.icon} /> : ""}&nbsp;</TableCell>
            <TableCell>
                {props.children ?? (
                    <ChangeHighlight>
                        <Text quote italic ref={ref}>
                            {props.text ?? ""}
                        </Text>
                    </ChangeHighlight>
                )}
            </TableCell>
        </TableRow>
    )
}

export default BlockData
